import { IEnvironment } from './interface/IEnvironment.interface';
import { PREFIX, SERVICES } from './path-consts/prefix.env';

export const environment: IEnvironment = {
  state: 'DEVELOPMENT',
  FE_URL: `https://tmtrav-finance-${PREFIX.DEV}.tma-swerp.com/`,
  // FE_URL: `https://marketplace-finance.tma-swerp.com/`,
  FE_URL_SALE: `https://tmtrav-ecommerce-${PREFIX.DEV}.tma-swerp.com/`,
  // FE_URL_SALE: `https://marketplace.tma-swerp.com /`,
  BE_URL: `https://api-${PREFIX.DEV}-tmtrav.tma-swerp.com/`,
  IFRAME_CONNECT_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com/app-connect`,
  LOGIN_URL: `https://tmtrav-${PREFIX.DEV}.tma-swerp.com`,
  SERVICES: SERVICES,
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'TMTravel-white',
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  FE_URL_MANAGEMENT: `https://tmtrav-ecommerce-management-${PREFIX.DEV}.tma-swerp.com/`,
  APP_NAME: 'TMTravel',
  REFUND_URL: `https://tmtrav-shop-admin-dev.tma-swerp.com/`,
};